<template>
  <div
      class="image-grid"
      :class="computedClasses.imageGrid"
      ref="imageGridRef"
      @wheel.passive="handleWheel"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
  >
    <div
        v-for="(image, index) in visibleImages"
        :key="image.id + '-' + index"
    :class="['image-container', computedClasses.imageContainer]"
    :data-image-index="index"
    :data-image-id="image.id"
    >
    <img
        :src="getImageUrl(image, isFullscreen)"
        :alt="image.attributes.name"
        class="thumbnail"
        loading="lazy"
        :class="computedClasses.thumbnail"
        @click.stop="handleImageClick(index, $event)"
        @load="onImageLoaded"
        draggable="false"
    />
  </div>

  <div v-if="isLoading">
    <div class="spinner-container">
      <div class="spinner"></div>
    </div>
  </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { getImageUrl } from '@/utils/imageLoader'
import { useTouch } from '@/hooks/useTouch'

export default {
  name: 'ImageGrid',
  props: {
    project: {
      type: Object,
      required: true
    },
    fullscreenProject: {
      type: Object,
      default: null
    },
    imagesAreVisible: {
      type: Boolean,
      default: false
    },
    currentImageIndex: {
      type: Number,
      default: null
    }
  },
  setup(props, {emit}) {
    const imageGridRef = ref(null)
    const isFullscreen = computed(() => props.fullscreenProject?.id === props.project?.id)
    const isLoading = ref(false)
    const visibleImages = ref([])
    const batchSize = 10
    const currentStartIndex = ref(0)
    const wheelEvents = ref([])
    const isNavigating = ref(false)
    const THRESHOLD = 550
    const isDuplicateCheck = ref(new Set())

    const handleNavigation = (direction) => {
      isNavigating.value = true
      emit('navigate', direction)
      setTimeout(() => {
        isNavigating.value = false
      }, 500)
    }

    const totalImages = computed(() => props.project.attributes.images.data.length)

    const {
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      isSwiping
    } = useTouch({
      isFullscreen,
      isNavigating,
      onNavigate: handleNavigation
    })

    const loadBatch = async () => {
      if (isLoading.value) return
      isLoading.value = true

      try {
        // The timeout here simulates a network delay; adjust or remove as needed.
        await new Promise((r) => setTimeout(r, 2000))

        const batch = props.project.attributes.images.data.slice(
            currentStartIndex.value,
            currentStartIndex.value + batchSize
        )

        // Filter out duplicates
        const newBatch = batch.filter(image => !isDuplicateCheck.value.has(image.id))
        newBatch.forEach(image => isDuplicateCheck.value.add(image.id))

        const loadPromises = newBatch.map((image) => {
          return new Promise((resolve) => {
            const img = new Image()
            img.src = getImageUrl(image, false)
            img.onload = () => resolve()
            img.onerror = () => resolve()
          })
        })

        await Promise.all(loadPromises)

        if (newBatch.length > 0) {
          visibleImages.value.push(...newBatch)
        }

        currentStartIndex.value += batchSize
      } finally {
        isLoading.value = false
        // Continue loading next batch if there are more images
        if (currentStartIndex.value < totalImages.value) {
          nextTick(() => loadBatch())
        }
      }
    }

    const handleImageClick = (index, event) => {
      emit('image-click', props.project, index, event)
    }

    const onImageLoaded = (event) => {
      emit('image-load', props.project.id, event.target.alt)
    }

    const handleWheel = (event) => {
      if (!isFullscreen.value || isNavigating.value) return

      // Lower threshold for mobile
      const THRESHOLD = window.innerWidth <= 768 ? 150 : 550

      wheelEvents.value.push({
        deltaX: event.deltaX,
        timestamp: Date.now()
      })

      wheelEvents.value = wheelEvents.value.filter(
          (e) => Date.now() - e.timestamp < 200
      )

      const totalDeltaX = wheelEvents.value.reduce((sum, e) => sum + e.deltaX, 0)

      if (Math.abs(totalDeltaX) > THRESHOLD) {
        isNavigating.value = true
        const direction = totalDeltaX > 0 ? 1 : -1
        emit('navigate', direction)

        wheelEvents.value = []
        setTimeout(() => {
          isNavigating.value = false
        }, 500)
      }
    }

    watch(isFullscreen, (newVal) => {
      if (newVal) {
        isDuplicateCheck.value.clear()
        visibleImages.value = props.project.attributes.images.data
      }
    })

    // Reset and start loading when project changes
    watch(() => props.project.id, () => {
      if (!isFullscreen.value) {
        currentStartIndex.value = 0
        visibleImages.value = []
        isDuplicateCheck.value.clear()
        loadBatch()
      }
    })

    onMounted(() => {
      if (!isFullscreen.value) {
        loadBatch()
      }
    })

    const computedClasses = computed(() => ({
      imageGrid: {
        'image-grid-enlarged': isFullscreen.value,
        'image-grid-hidden': props.fullscreenProject && !isFullscreen.value
      },
      imageContainer: {
        'image-container-enlarged': isFullscreen.value,
        'image-container-hidden': props.fullscreenProject && !isFullscreen.value
      },
      thumbnail: {
        'thumbnail-enlarged': isFullscreen.value,
        'thumbnail-hidden': props.fullscreenProject && !isFullscreen.value,
        'images-visible': isFullscreen.value && props.imagesAreVisible
      }
    }))

    return {
      isFullscreen,
      computedClasses,
      imageGridRef,
      handleImageClick,
      visibleImages,
      isLoading,
      getImageUrl,
      handleWheel,
      onImageLoaded,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
    }
  }
}
</script>

<style scoped>
.image-grid {
  touch-action: none;
}

.image-grid:not(.image-grid-enlarged) {
  touch-action: auto;
}

.hovered {
  outline: 2px solid rgba(0, 0, 0, 0.1);
}
</style>