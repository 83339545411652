// // hooks/useTouch.js
// import { ref } from 'vue';
//
// export const useTouch = (onNavigate) => {
//     const touchStartX = ref(null);
//     const touchStartY = ref(null);
//     const touchMoved = ref(false);
//     const touchThreshold = 10; // Threshold to distinguish between tap and swipe
//
//     const handleTouchStart = (event) => {
//         touchMoved.value = false;
//         touchStartX.value = event.touches[0].clientX;
//         touchStartY.value = event.touches[0].clientY;
//     };
//
//     const handleTouchMove = (event) => {
//         if (touchStartX.value === null) return;
//
//         const deltaX = event.touches[0].clientX - touchStartX.value;
//         const deltaY = event.touches[0].clientY - touchStartY.value;
//
//         if (Math.abs(deltaX) > touchThreshold || Math.abs(deltaY) > touchThreshold) {
//             touchMoved.value = true;
//         }
//     };
//
//     const handleTouchEnd = (event) => {
//         if (touchStartX.value === null) return;
//
//         const deltaX = event.changedTouches[0].clientX - touchStartX.value;
//         const deltaY = event.changedTouches[0].clientY - touchStartY.value;
//
//         const absDeltaX = Math.abs(deltaX);
//         const absDeltaY = Math.abs(deltaY);
//
//         // Determine if it was a swipe
//         if (touchMoved.value && absDeltaX > absDeltaY) {
//             if (absDeltaX > 50) {
//                 onNavigate(deltaX < 0 ? 1 : -1);
//             }
//         }
//
//         touchStartX.value = null;
//         touchStartY.value = null;
//         touchMoved.value = false;
//     };
//
//     return {
//         handleTouchStart,
//         handleTouchMove,
//         handleTouchEnd
//     };
// };

// useTouch.js
import { ref, computed } from 'vue'

export const useTouch = ({ isFullscreen, isNavigating, onNavigate }) => {
    const touchStart = ref({ x: 0, y: 0 })
    const touchEnd = ref({ x: 0, y: 0 })
    const isSwiping = ref(false)

    const getThreshold = () => {
        const isMobile = window.innerWidth <= 768
        return isMobile ? 50 : 150
    }

    const handleTouchStart = (event) => {
        if (!isFullscreen.value || isNavigating.value) return

        const touch = event.touches[0]
        touchStart.value = {
            x: touch.clientX,
            y: touch.clientY
        }
        isSwiping.value = true
    }

    const handleTouchMove = (event) => {
        if (!isSwiping.value) return
        event.preventDefault()
    }

    const handleTouchEnd = (event) => {
        if (!isSwiping.value) return

        const touch = event.changedTouches[0]
        touchEnd.value = {
            x: touch.clientX,
            y: touch.clientY
        }

        const deltaX = touchEnd.value.x - touchStart.value.x
        const deltaY = Math.abs(touchEnd.value.y - touchStart.value.y)

        // Only handle horizontal swipes
        if (deltaY < 100) {
            const threshold = getThreshold()

            if (Math.abs(deltaX) > threshold) {
                const direction = deltaX > 0 ? -1 : 1 // Swipe left means next, right means previous
                onNavigate(direction)
            }
        }

        isSwiping.value = false
    }

    return {
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
        isSwiping
    }
}