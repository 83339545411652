// src/utils/scrollHelpers.js
export async function scrollToImage(project, index) {
    // A slight delay (or use nextTick in your component) to allow DOM updates.
    await new Promise(resolve => setTimeout(resolve, 0));
    const selector = `.project-row-enlarged[data-project-id="${project.id}"] .image-container-enlarged:nth-child(${index + 1})`;
    const el = document.querySelector(selector);
    if (el) {
        el.scrollIntoView({
            behavior: 'auto',
            inline: 'start',
            block: 'nearest'
        });
    }
}