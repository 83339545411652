<template>
  <div class="index">
    <div class="projects">
      <div
          v-for="project in sortedProjects"
          :key="project.id"
          :class="projectRowClasses(project)"
          :data-project-id="project.id"
          :style="getProjectStyles(project)"
      >
        <ProjectInfo
            :name="project.attributes.name"
            :date="project.attributes.date"
            :description="project.attributes.description"
            @click-header="closeFullscreen"
        />
        <!-- Spinner overlay appears only while the first critical batch is loading -->
        <div v-if="isCriticalLoading(project.id)" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <ImageGrid
            :project="project"
            :fullscreen-project="fullscreenProject"
            :images-are-visible="imagesAreVisible"
            :current-image-index="currentImageIndex"
            @image-click="handleImageClick"
            @navigate="handleNavigate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick, onUnmounted, watch, reactive } from 'vue';
import ProjectInfo from './projects/ProjectInfo.vue';
import ImageGrid from './projects/ImageGrid.vue';
import { fetchProjects, sortProjectsByDate } from '@/api';
import { findAdjacentProject } from '@/utils/imageLoader';
import { useModalState } from '@/hooks/useModalState';
import {
  loadCriticalImages,
  loadInitialProjects,
  preloadAdjacentProjects
} from '@/utils/projectsLoader';
import { scrollToImage } from '@/utils/scrollHelpers';

export default {
  name: 'ProjectsComponent',
  components: { ProjectInfo, ImageGrid },
  setup() {
    const projects = ref([]);
    const fullscreenProject = ref(null);
    const currentImageIndex = ref(null);
    const imagesAreVisible = ref(false);
    const clickedImageRect = ref(null);
    const isInitialLoading = ref(true);
    const initialProjectsLoaded = ref(false);
    // Track which projects are currently showing a spinner (i.e. loading critical images)
    const criticalLoadingProjects = ref(new Set());
    // Cache for projects that have already loaded their critical images
    const projectLoaded = reactive({});

    const sortedProjects = computed(() => sortProjectsByDate(projects.value));
    const { setModalState } = useModalState();

    const projectRowClasses = (project) => ({
      'project-row-normal': !fullscreenProject.value || fullscreenProject.value.id !== project.id,
      'project-row-enlarged': fullscreenProject.value?.id === project.id
    });

    const getProjectStyles = (project) => {
      if (fullscreenProject.value?.id !== project.id) return {};
      return clickedImageRect.value
          ? {
            transition: 'transform 0.3s ease-out, opacity 0.3s ease',
            transformOrigin: `${clickedImageRect.value.left + clickedImageRect.value.width / 2}px ${clickedImageRect.value.top + clickedImageRect.value.height / 2}px`
          }
          : {};
    };

    const isCriticalLoading = (projectId) => criticalLoadingProjects.value.has(projectId);

    const initializeProjects = async () => {
      try {
        isInitialLoading.value = true;
        const { initialProjects, allProjects } = await loadInitialProjects(fetchProjects);

        // Set initial projects
        projects.value = initialProjects;
        await nextTick();

        // Load all projects after a short delay
        setTimeout(() => {
          projects.value = allProjects;
          initialProjectsLoaded.value = true;
        }, 100);

      } finally {
        isInitialLoading.value = false;
      }
    };

    const handleImageClick = async (project, index, event) => {
      // If already in fullscreen, ignore clicks.
      if (fullscreenProject.value) return;

      currentImageIndex.value = index;
      clickedImageRect.value = event.target.getBoundingClientRect();

      // If this project has not yet loaded its critical images, load them
      if (!projectLoaded[project.id]) {
        criticalLoadingProjects.value.add(project.id);
        await loadCriticalImages(project, index);
        criticalLoadingProjects.value.delete(project.id);
        projectLoaded[project.id] = true;
      }

      // Open the project in fullscreen
      fullscreenProject.value = project;
      await nextTick();
      // Use smooth scrolling to bring the clicked image fully into view.
      await scrollToImage(project, index, { behavior: 'smooth' });
      imagesAreVisible.value = true;
      clickedImageRect.value = null;

      // Preload adjacent projects' images
      await preloadAdjacentProjects(sortedProjects.value, project);
    };

    const handleNavigate = async (direction) => {
      if (!fullscreenProject.value || currentImageIndex.value === null) return;
      const project = fullscreenProject.value;
      const totalImages = project.attributes.images.data.length;
      const newIndex = currentImageIndex.value + direction;

      if (newIndex < 0) {
        const prevProject = findAdjacentProject(sortedProjects.value, project, -1);
        if (prevProject) {
          await navigateToProject(prevProject, prevProject.attributes.images.data.length - 1);
        }
      } else if (newIndex >= totalImages) {
        const nextProject = findAdjacentProject(sortedProjects.value, project, 1);
        if (nextProject) {
          await navigateToProject(nextProject, 0);
        }
      } else {
        currentImageIndex.value = newIndex;
        await scrollToImage(project, newIndex, { behavior: 'smooth' });
      }
    };

    const navigateToProject = async (project, targetIndex) => {
      if (!project) return;
      imagesAreVisible.value = false;
      // Only load critical images if not already loaded
      if (!projectLoaded[project.id]) {
        await loadCriticalImages(project, targetIndex);
        projectLoaded[project.id] = true;
      }
      fullscreenProject.value = project;
      currentImageIndex.value = targetIndex;
      await nextTick();
      await scrollToImage(project, targetIndex, { behavior: 'smooth' });
      imagesAreVisible.value = true;
    };

    const closeFullscreen = () => {
      if (fullscreenProject.value) {
        fullscreenProject.value = null;
        currentImageIndex.value = null;
        imagesAreVisible.value = false;
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeFullscreen();
      } else if (event.key === 'ArrowLeft' && fullscreenProject.value) {
        event.preventDefault();
        handleNavigate(-1);
      } else if (event.key === 'ArrowRight' && fullscreenProject.value) {
        event.preventDefault();
        handleNavigate(1);
      }
    };

    onMounted(async () => {
      try {
        projects.value = await fetchProjects();
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
      document.addEventListener('keydown', handleKeyDown);
      document.documentElement.style.overscrollBehavior = 'none';
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeyDown);
      document.documentElement.style.overscrollBehavior = '';
    });

    watch(fullscreenProject, (value) => {
      setModalState(!!value);
    });

    return {
      sortedProjects,
      fullscreenProject,
      imagesAreVisible,
      currentImageIndex,
      projectRowClasses,
      getProjectStyles,
      handleImageClick,
      handleNavigate,
      closeFullscreen,
      isCriticalLoading,
      isInitialLoading,
      initialProjectsLoaded
    };
  }
};
</script>

<style scoped>
.projects {
  margin-top: 35vh;
}

/* Spinner container for critical image loading (centered on the image grid) */
.spinner-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Example spinner styles */
.spinner {
  /* your spinner CSS (e.g., size, border, animation) */
}

/* Project row styles */
.project-row-normal {
  z-index: 1;
}
.project-row-enlarged {
  position: fixed;
  inset: 0;
  padding-top: 40px;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overscroll-behavior: none;
}
</style>